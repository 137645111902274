<template>
    <div class="list_wd">
        <nut-cell @click-cell="dialogShow=true" title="手环亲情号码" :desc="phone" :show-icon="true">
        </nut-cell>
        <nut-dialog title="手环亲情号码" :visible="dialogShow" @ok-btn-click="setPhone"
                    @cancel-btn-click="reload" @close="dialogShow=false">
            <nut-textinput
                    v-model="phone"
                    placeholder="亲情号码1"
                    :clearBtn="true"
                    :disabled="false"
            />
            <nut-textinput
                    v-model="phone1"
                    placeholder="亲情号码2"
                    :clearBtn="true"
                    :disabled="false"
            />
            <nut-textinput
                    v-model="phone2"
                    placeholder="亲情号码3"
                    :clearBtn="true"
                    :disabled="false"
            />
            <nut-textinput
                    v-model="phone3"
                    placeholder="亲情号码4"
                    :clearBtn="true"
                    :disabled="false"
            />
        </nut-dialog>
        <nut-cell @click-cell="isVisible=true" title="无任何触发提醒" :desc="trigger" :show-icon="true">
        </nut-cell>
        <nut-actionsheet :is-visible="isVisible"
                         @close="switchActionSheet"
                         :menu-items="menuItems"
                         @choose="chooseItem"
                         cancelTxt="取消"
                         :chooseTagValue="trigger"
        ></nut-actionsheet>
        <nut-cell @click-cell="isVisible1=true" title="卫生间超时提醒" :desc="shit" :show-icon="true">
        </nut-cell>
        <nut-actionsheet :is-visible="isVisible1"
                         @close="switchActionSheet1"
                         :menu-items="menuItems1"
                         @choose="chooseItem1"
                         cancelTxt="取消"
                         :chooseTagValue="shit"
        ></nut-actionsheet>
        <nut-cell @click-cell="isVisible2=true" title="早上未起床提醒" :desc="morning" :show-icon="true">
        </nut-cell>
        <nut-actionsheet :is-visible="isVisible2"
                         @close="switchActionSheet2"
                         :menu-items="menuItems2"
                         @choose="chooseItem2"
                         cancelTxt="取消"
                         :chooseTagValue="morning"
        ></nut-actionsheet>
        <nut-cell @click-cell="isVisible3=true" title="外出未归提醒" :desc="night" :show-icon="true">
        </nut-cell>
        <nut-actionsheet :is-visible="isVisible3"
                         @close="switchActionSheet3"
                         :menu-items="menuItems3"
                         @choose="chooseItem3"
                         cancelTxt="取消"
                         :chooseTagValue="night"
        ></nut-actionsheet>
    </div>
</template>

<script>
    import {getMyoldman, updateUserSet, getUserSet, getUserFamilyPhone, setUserFamilyPhone} from "../api/getMyoldman";

    export default {
        name: "Setting",
        created() {
            getUserFamilyPhone(window.wristband).then(res => {
                var phones = res.data.data[0].familyPhone.split(".");
                this.phone = phones[0]
                this.phone1 = phones[1]
                this.phone2 = phones[2]
                this.phone3 = phones[3]
                this.familyPhone = res.data.data[0].familyPhone
            })
            getMyoldman(sessionStorage.getItem('openId')).then(res => {
                this.myoldman = res.data.data
                this.oldman = this.myoldman[sessionStorage.getItem("ind") ? sessionStorage.getItem("ind") : 0]
                getUserSet(this.oldman.userId).then(res2 => {
                    var setting = res2.data.data
                    for (var i of setting) {
                        switch (i.abnoParaId) {
                            case "all_no_trigger_duration":
                                this.trigger = i.abnoParaValue / 3600 + '小时';
                                break;
                            case "unusual_toilet_duration":
                                this.shit = i.abnoParaValue / 60 + '分钟';
                                break;
                            case "unusual_getup_time":
                                this.morning = i.abnoParaValue + '时';
                                break;
                            case "unusual_night_return_time":
                                this.night = i.abnoParaValue + '时';
                                break;
                        }
                    }
                });


            })
        },
        data() {
            return {
                dialogShow: false,
                dialogShow1: false,
                dialogShow2: false,
                dialogShow3: false,
                oldman: null,
                isVisible: false,
                isVisible1: false,
                isVisible2: false,
                isVisible3: false,
                trigger: '2小时',
                familyPhone: '',
                shit: '30分钟',
                morning: '7时',
                night: '20时',
                phone: '',
                phone1: '',
                phone2: '',
                phone3: '',
                menuItems: [
                    {
                        'name': '1小时',
                        'value': 1
                    },
                    {
                        'name': '2小时',
                        'value': 2
                    },
                    {
                        'name': '3小时',
                        'value': 3
                    },
                    {
                        'name': '4小时',
                        'value': 4
                    },
                    {
                        'name': '5小时',
                        'value': 5
                    },
                    {
                        'name': '6小时',
                        'value': 6
                    }
                ],
                menuItems1: [
                    {
                        'name': '20分钟',
                        'value': 20
                    },
                    {
                        'name': '25分钟',
                        'value': 25
                    },
                    {
                        'name': '30分钟',
                        'value': 30
                    },
                    {
                        'name': '35分钟',
                        'value': 35
                    },
                    {
                        'name': '40分钟',
                        'value': 40
                    },
                    {
                        'name': '45分钟',
                        'value': 45
                    },
                ],
                menuItems2: [
                    {
                        'name': '5时',
                        'value': 5
                    },
                    {
                        'name': '6时',
                        'value': 6
                    },
                    {
                        'name': '7时',
                        'value': 7
                    },
                    {
                        'name': '8时',
                        'value': 8
                    },
                    {
                        'name': '9时',
                        'value': 9
                    },
                    {
                        'name': '10时',
                        'value': 10
                    },
                ],
                menuItems3: [
                    {
                        'name': '18时',
                        'value': 18
                    },
                    {
                        'name': '19时',
                        'value': 19
                    },
                    {
                        'name': '20时',
                        'value': 20
                    },
                    {
                        'name': '21时',
                        'value': 21
                    },
                    {
                        'name': '22时',
                        'value': 22
                    },
                    {
                        'name': '23时',
                        'value': 23
                    },
                ],
            }
        },
        methods: {
            switchActionSheet() {
                this.isVisible = !this.isVisible;
            },
            switchActionSheet1() {
                this.isVisible1 = !this.isVisible1;
            },
            switchActionSheet2() {
                this.isVisible2 = !this.isVisible2;
            },
            switchActionSheet3() {
                this.isVisible3 = !this.isVisible3;
            },
            chooseItem(itemParams) {
                debugger;
                this.trigger = itemParams.name;
                updateUserSet(this.oldman.userId, 'field=all_no_trigger_duration&value=' + itemParams.value * 3600)
            },
            chooseItem1(itemParams) {
                this.shit = itemParams.name;
                updateUserSet(this.oldman.userId, 'field=unusual_toilet_duration&value=' + itemParams.value * 60)
            },
            chooseItem2(itemParams) {
                this.morning = itemParams.name;
                updateUserSet(this.oldman.userId, 'field=unusual_getup_time&value=' + itemParams.value)
            },
            chooseItem3(itemParams) {
                this.night = itemParams.name;
                updateUserSet(this.oldman.userId, 'field=unusual_night_return_time&value=' + itemParams.value)
            },
            setPhone() {
                this.dialogShow = false
                this.dialogShow1 = false
                this.dialogShow2 = false
                this.dialogShow3 = false
                var phones = new Array()
                phones.push(this.phone)
                phones.push(this.phone1)
                phones.push(this.phone2)
                phones.push(this.phone3)
                var s = phones.join(".")
                this.familyPhone = s
                setUserFamilyPhone(window.wristband, s)
            },
            reload() {
                var phones = this.familyPhone.split(".");
                this.phone = phones[0]
                this.phone1 = phones[1]
                this.phone2 = phones[2]
                this.phone3 = phones[3]
            },
            reload1() {
                var phones = this.familyPhone.split(".");
                this.phone1 = phones[1]
            },
            reload2() {
                var phones = this.familyPhone.split(".");
                this.phone2 = phones[2]
            },
            reload3() {
                var phones = this.familyPhone.split(".");
                this.phone3 = phones[3]
            },
        }
    }
</script>

<style scoped>

</style>